import React, { FC, useEffect } from "react";
import { StyleSheet, Image, Text } from "react-native";
import Animated, { Easing, interpolate, useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";

interface WidgetProps {
  name: string;
}

const Widget: FC<WidgetProps> = ({ name }) => {
  const nameOffset = useSharedValue(0);

  useEffect(() => {
    nameOffset.value = 0;
    nameOffset.value = withTiming(1, {
      duration: 200,
      easing: Easing.inOut(Easing.ease),
    });
  }, [name]);

  const nameAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: nameOffset.value,
      transform: [
        {
          translateX: interpolate(
            nameOffset.value,
            [0, 1],
            [-6, 0],
          ),
        },
      ],
    };
  });

  return (
    <Animated.View style={styles.body}>
      <Animated.View style={nameAnimatedStyle}>
        <Text style={styles.name}>{name}</Text>
      </Animated.View>
    </Animated.View>
  );
};

export default Widget;

const styles = StyleSheet.create({
  body: {
    borderRadius: 16,
    borderCurve: "continuous",
    height: 106,
    width: 228,
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
  },
  name: {
    fontSize: 14,
    fontWeight: "700",
    color: "black",
    marginTop: 6,
    marginLeft: 12,
  },
});
